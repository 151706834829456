.container-notification {
    margin-right: 1%;
}
.container-icon-notification {
    cursor: pointer;
}

.title-notification {
    margin-bottom: 0.1rem;
    color: #2D1674;
    font-weight: 600;
    font-family: 'poppins';
}

.message-notification {
    color: black;
    font-family: 'poppins';
    font-size: 14px;
}

.title-notification-not-reader {
    margin-bottom: 0.1rem;
    color: #2D1674;
    font-weight: bold;
    font-family: 'poppins';
}

.message-notification-not-reader {
    color: black;
    font-weight: 600;
    font-size: 14px;
    font-family: 'poppins';
}

.container-message-not-reader {
    cursor: pointer;
}

.style-notification {
    background-color: white;
    height: 380px;
    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 10px 20px;
    width: 130%;
    margin-top: 3%;
    margin-left: -70%;
    overflow-y: scroll;
    z-index: 2;
}
.style-notification::-webkit-scrollbar {
    width: 8px;
}
 
.style-notification::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 20px #2D1674; 
    border-radius: 10px;
}
 
.style-notification::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 10px #2D1674; 
}

.style-popper {
    width: 20%;
}

.container-notification-badge {
    margin-left: 100%;
    margin-top: -30%;
    margin-bottom: -70%;
}

.title-different-days {
    font-size: 12px;
    margin-top: 5px;
    color: #2D1674;
}

.container-not-notification {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90%;
}

.title-not-notification {
    border: 1px solid #2D1674;
    color: #2D1674;
    padding: 4px 4px 6px 5px;
    border-radius: 9px;
}

@media only screen and (max-width: 800px ) {
    .style-notification {
        background-color: white;
        height: 380px;
        box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        padding: 10px;
        width: 170%;    
        margin-top: 5%;
        margin-left: -80%;
    }
    .style-popper {
        width: 50%;
    }
}