@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.container-marketing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    font-family: 'poppins';
    width: 100%;
    /* height: 100%; */
}

.card-marketing {
    width: 40%;
    display: grid;
    grid-template-columns: 30% 60% 10%;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 10px;
    cursor: pointer;
}

.card-marketing-soon {
    background-color: #cccccc;
    width: 40%;
    display: grid;
    grid-template-columns: 30% 60% 10%;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 10px;
    cursor: pointer;
}



.image-card {
    height: 140px;
    width: 140px;
}

.container-title-card-marketing {
    padding: 10px 10px;
}

.container-title-soon-card-marketing {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text-soon {
    color: white;
    font-size: 10px;
    background-color: #4cb582;
    padding: 1px 5px 15px 5px;
    font-weight: bold;
    border-radius: 10px;
    margin-left: 5%;
    height: 15px;
}

.container-icon-card-marketing {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title-card-marketing {
    font-size: 22px;
    font-weight: 700;
}

.sub-title-card-marketing {
    font-size: 13px;
    margin-top: 2%;
}



.container-render-images {
    width: 100%;
    height: 230px;
}

.content-render-images {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes changeColorAndMove {
    0% {
      color: red;
      transform: translateY(0);
    }
    25% {
      color: blue;
      transform: translateY(-10px);
    }
    50% {
      color: green;
      transform: translateY(0);
    }
    75% {
      color: orange;
      transform: translateY(10px);
    }
    100% {
      color: purple;
      transform: translateY(0);
    }
  }
  
  .change-color-and-move {
    animation: changeColorAndMove 5s infinite;
    font-weight: 700;
  }

.container-digit-logo {
    margin-top: 10%;
}

.container-images-generate {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
    width: 50%;
    margin-top: 5%;
}

.images-size {
    width: 200px;
    height: 200px;
}

.input-style-marketing {
    width: 100%;
    height: 100px;
    padding: 0px;
    padding-left: 3%;
    padding-top: 10px;
    border-radius: 20px;
    font-family: 'Poppins', sans-serif;
    border: 2px solid #2D1674;
}

.button-generate-logo {
    margin-top: 3%;
    background-color: #5b3faf;
    color: white;
    width: 50%;
    cursor: pointer;
    padding: 10px;
    display: flex;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}

.button-generate-logo:hover {
    margin-top: 3%;
    background-color: transparent;
    border: 2px solid #5b3faf;
    color: #5b3faf;
    font-weight: 700;
    width: 50%;
    cursor: pointer;
    padding: 10px;
    display: flex;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    transition: all 0.1s !important;
}


.title-create-logo {
    margin-left: 5%;
    font-size: 30px;
}

.icon-return-marketing {
    cursor: pointer;
}

.container-return-menu-marketing {
    width: 90%;
}
.content-return-menu-marketing {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 5%;
}

.content-conteudos {
    height: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-top: 8%;
}

.content-conteudos::-webkit-scrollbar {
    width: 3px;
    height: 6px; /* A altura só é vista quando a rolagem é horizontal */

  }
  
  .content-conteudos::-webkit-scrollbar-track {
    background: transparent;
    padding: 12px;
    margin-top: 10px;
  }
  
  .content-conteudos::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 10px;
    margin-top: 5%;
  }

.container-steps {
    margin-top: 5%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-steps-button {
    margin-top: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title-steps {
    margin-bottom: 4%;
    font-size: 20px;
    font-weight: 600;
}

.button-next-steps {
    background-color: #2D1674;
    color: white;
}

.button-next-steps:hover {
    background-color: #5b3faf !important;
    color: white !important;
    border-color: #5b3faf !important;
}

@media only screen and (max-width: 800px ) {
    .container-marketing {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: 'poppins';
        width: 100%;
        margin-bottom: 10%;
        margin-top: 30%;
        margin-left: -12%;
        /* height: 100%; */
    }

    .card-marketing {
        width: 120%;
        display: grid;
        grid-template-columns: 30% 60% 10%;
        box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        margin-top: 10px;
        cursor: pointer;
    }
   
    .image-card {
        height: 120px;
        width: 120px;
    }

    .container-return-menu-marketing {
        width: 100%;
    }
    .content-return-menu-marketing {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 5%;
    }

    .content-conteudos {
        height: 400px;
        overflow-x: scroll;
        overflow-y: scroll;
        margin-top: 8%;
        width: 100%;
    }
}



