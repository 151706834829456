.container-exits {
    width: 100%;
}

.content-exits {
    width: 80%;
    margin: auto;
    margin-top: 4%;
}

.container-content-exits {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 80%;
    margin-bottom: 3%;
    flex: 1;
}

.container-button-exits{
    width: 100%;
}

.container-button-exits-register{
    width: 100%;
    margin-top: 100%;
}

.container-search-exits {
    width: 60%;
    display: flex;
    justify-content: space-around;
}


.container-inputs-exits {
    width: 80%;
    margin-bottom: 2%;
    margin-right: 2%;
}

.container-content-exits {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: 100%;
    margin-bottom: 5%;
}



.container-table-exits {
    width: 85%;
}

.container-icons-table-exits {
    display: flex;
    justify-content: space-around;
    cursor: pointer;
    width: 55%;
    margin-left: -5%;
}
.container-input-edit-value {
    width: 20%;
}

.input-style-edit-exit-value {
    width: 90%;
    padding: 5px;
    padding-left: 3%;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    /* box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1); */
    border: 1px solid #2D1674;
    margin-right: 5%;
}

.input-style-edit-exit-date {
    width: 65%;
    padding: 5px;
    padding-left: 3%;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    /* box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1); */
    border: 1px solid #2D1674;
    margin-right: 5%;
}

.container-icon-download-exits {
    background-color: #2D1674;
    padding: 0px 10px 2.5px 10px ;
    border-radius: 50%;
    margin-top: 10%;
    margin-left: 35%;
    width: 100%;
}

.container-download-exits{
    display: flex;
    width: 100%;
}


.button-style-exits {
    width: 100%;
    padding: 7px 8px;
    background: #2D1674;
    border: none;
    border-radius: 20px;
    font-size: 13px;
    color: white;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    cursor: pointer;
}

.button-style-exits:hover {
    background: rgba(45, 22, 116, 0.9)
}

.container-icons-edit-exits {
    width: 15%;
    display: flex;
    /* margin: 0px; */
}

@media only screen and (max-width: 800px ) {
    .container-exits {
        width: 100%;
        margin-bottom: 10%;
        margin-top: 30%;
        margin-left: -12%;
    }

    .container-button-exits-register{
        width: 100%;
        margin-top: 120%;
    }

    .input-style-edit-exit-value {
        padding: 5px;
        padding-left: 3%;
        border-radius: 10px;
        font-family: 'Poppins', sans-serif;
        border: 1px solid #2D1674;
        margin-right: 5%;
    }

    .container-input-edit-value {
        width: 100%;
    }

    .content-exits {
        width: 100%;
        margin: auto;
        margin-top: 4%;
    }
    
    .container-inputs-content-exits {
        width: 100%;
        margin-bottom: 2%;
    }
    
    .container-content-exits {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 10%;
    }

    .container-button-exits{
        width: 100%;
        margin-top: 2%;
    }

    .title-inputs-exits {
        margin-bottom: 0.3rem;
    }

    .container-search-exits {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        margin-bottom: 15%;
    }

    .container-inputs-exits {
        width: 100%;
        margin-bottom: 2%;
    }

    .container-table-exits {
        width: 100%;
    }

    .container-icons-table-exits {
        width: 100%;
    }

    .container-inputs-table-responsive { 
        display: flex;
        flex-direction: column;
        width: 180%;
    }
   
}