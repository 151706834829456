.container-button-more{
    width: 100%;
    display: flex;
    margin: auto;
    justify-content: center;
}

.button-load-more {
    width: auto;
    padding: 4px 1px 7px 10px;
    border: none;
    width: 50%;
    background-color: #5331b8;
    border-radius: 8px;
    color: white;
}

.button-load-more:hover {
    width: auto;
    padding: 4px 1px 7px 10px;;
    border: 1px solid #5331b8;
    width: 50%;
    background-color: white;
    border-radius: 8px;
    color: #5331b8;
    transition: all 0.2s !important;
}

@media only screen and (max-width: 800px ) {
    .container-button-more{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .button-load-more {
        padding: 4px 1px 7px 10px;
        border: none;
        width: 60%;
        background-color: #5331b8;
        border-radius: 8px;
        color: white;
    }
    
    .button-load-more:hover {
        padding: 4px 1px 7px 10px;;
        border: 1px solid #5331b8;
        width: 60%;
        background-color: white;
        border-radius: 8px;
        color: #5331b8;
        transition: all 0.2s !important;
    }
}