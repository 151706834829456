.container-sales {
    width: 100%;
    margin-bottom: 10%;
}

.container-total-value {
    display: flex;
    width: 65%;
    justify-content: space-between;
    margin-bottom: 2%;
}

.total-values {
    border: 2px solid #2D1674;
    color: #2D1674;
    padding: 3px 15px;
    border-radius: 20px;
    font-weight: 700;
    margin-right: 5px;
}

.total-sales-page {
    border: 2px solid #8C52FF;
    color: #8C52FF;
    padding: 3px 15px;
    border-radius: 20px;
    font-weight: 700;
    margin-right: 5px;
}

.container-button-sales{
    width: 60%;
    margin-top: 1%;
}

.content-sales {
    width: 80%;
    margin: auto;
    margin-top: 4%;
}

.container-search-sales {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 80%;
    margin-bottom: 3%;
}

.container-inputs-sales-page {
    width: 26%;
    margin-right: 1%;
}

.input-style-sales {
    width: 100%;
    padding: 5px;
    padding-left: 3%;
    border-radius: 10px;
    margin-right: 20%;
    font-family: 'Poppins', sans-serif;
    /* box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1); */
    border: 1px solid #2D1674;
}

.container-cards-sales {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.container-card-sale {
    width: 81%;
    position: relative;
    height: 100%;
    padding: 0px 0px 20px 0px;
        box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.container-date-total-sales {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 5%;
    background-color: #2D1674 ;
    border-radius: 10px 10px 0px 0px;
    padding: 3px 10px;
    color: black;
    font-weight: 400;
    border-bottom: 1px solid white;
}

.container-list-sales {
    padding: 3px 10px;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid black;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #363636;
    margin-bottom: 4%;
}

.product-sale {
    margin-bottom: 0rem;
}

.total-sale {
    color: white;
    font-weight: 500;
}

.date-sale {
    font-weight: 500;
    color: #e0d1ff;
}

.container-icon-percent {
    display: flex;
    justify-content: end;
    align-items: flex-end;
}

.container-loading-sale {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 20%;
    margin-left: -5%;
}

.container-download { 
    margin-left: 2%;
}

.container-icon-download {
    background-color: #2D1674;
    padding: 2px 10px;
    border-radius: 50%;
    margin-top: 10%;
}


.container-icon-delete-sale {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 2%;
    margin-bottom: 2.5%;
}

.container-footer {
    /* display: flex;
    position: relative; */
}

.container-payment{ 
    /* margin: auto; */
    text-align: center;
    /* margin-left: 20%; */
}
.container-paymentMethod {
    letter-spacing: 0.6px;
    font-size: 12px;
    background-color: rgba(201, 226, 101, 0.4);
    padding: 0px 10px;
    border-radius: 30px;
    font-weight: 700;
    width: fit-content;
}

.table-total-sale {
    font-weight: 700;
}

.button-style-sales {
    width: 40%;
    padding: 7px 8px;
    background: #2D1674;
    border: none;
    border-radius: 7px;
    font-size: 13px;
    color: white;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    cursor: pointer;
}

.container-table-sales {
    width: 90%;
    /* text-align: center;  */
    /* margin: auto; */
}

.container-tbody{
    align-items: center;
}

.container-not-sales {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    text-align: center;
}

.text-not-sales{
    font-size: 22px;
    color: #2D1674;
}

.text-link-cart {
    font-size: 22px;
    color: #2c156e;
    font-weight: 700;
    cursor: pointer;
}

@media only screen and (max-width: 800px ) {
    .container-sales {
        width: 100%;
        margin-bottom: 10%;
        margin-top: 30%;
        margin-left: -15%;
    }

    .container-button-sales {
        width: 100%;
        margin-top: 1%;
    }

    .button-style-sales {
        width: 100%;
        padding: 7px 8px;
        background: #2D1674;
        border: none;
        border-radius: 20px;
        font-size: 13px;
        color: white;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 1px;
        cursor: pointer;
    }

    .content-sales {
        width: 100%;
        margin: auto;
        margin-top: 4%;
    }

    .container-total-value {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-bottom: 10%;
        font-size: 13px;
    }

    .container-inputs-sales-page {
        width: 100%;
        margin-bottom: 2%;
    }

    .total-values {
        border: 2px solid #2D1674;
        color: #2D1674;
        padding: 5px 15px;
        border-radius: 20px;
        font-weight: 700;
        margin-top: 0px;
        /* margin-block-end: 0px; */
    }

    .total-sales-page {
        border: 2px solid #8C52FF;
        color: #8C52FF;
        padding: 5px 15px;
        border-radius: 20px;
        font-weight: 700;
        margin-right: 10px;
        margin-top: 0px;
    }
    
    .container-cards-sales {
        display: flex;
        flex-direction: column;
    }

    .container-search-sales {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 55%;
        margin-bottom: 20%;
    }

    .container-card-sale {
        width: 100%;
        height: 100%;
        padding: 0px 0px 20px 0px;
        box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }

    .container-table-sales {
        width: 100%;
        text-align: center;
        font-size: 12px;
    }

    .container-paymentMethod {
        letter-spacing: 0.6px;
        font-size: 12px;
        background-color: rgba(201, 226, 101, 0.4);
        padding: 0px 10px;
        border-radius: 30px;
        font-weight: 700;
        width: fit-content;
        max-width: 15ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    
}

