@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300&display=swap');
.container-payment {
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
    font-family: 'Poppins', sans-serif;
}

.header-payment {
    height: 60px;
    width: 100%;
}

.img {
    width: 50px;
    height: 50px;
    margin-top: 2%;
}

.container-secure-pay {
    width: 90%;
    display: flex;
    justify-content: flex-end;
    margin-top: 1%;
}

.title-secure-pay{
    font-weight: 600;
    color: #2D1674;
}

.titles-payment {
    font-size: 22px;
    font-weight: 600;
    color: #484848;
    margin-bottom: 5%;
}

.content-payment {
    width: 80%;
    display: grid;
    grid-template-columns: 32% 30%;
    align-content: center;
    justify-content: center;
    margin: auto;
    margin-top: 2%;

}

.content-person {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 100%;
  box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 0px 20px;
  height: 445px;
  margin-top: 3%;
}

.inputs-content {
    width: 150%;
    margin-bottom: 2%;
    border: 1px solid #cab8ff;
    color: #4925b3;
    font-weight: 600;
    padding: 3px 10px; 
    border-radius: 10px;
}

.inputs-content::placeholder {
    font-weight: 600;
    opacity: 1;
    color: #cab8ff;
    font-size: 10px;
    padding-left: 5%;
    letter-spacing: 1px;
    z-index: 3;
}

.inputs-content:focus {
    border-color: #8e69ff;
}

.inputs-content:hover {
    width: 150%;
    margin-bottom: 2%;
    border: 1px solid #8e69ff;
}



.input-ddd {
    width: 98%;
    border: 1px solid #cab8ff;
    color: #4925b3;
    font-weight: 600;
    border-radius: 10px;
}

.input-ddd::placeholder {
    font-weight: 600;
    opacity: 1;
    color: #cab8ff;
    font-size: 10px;
    padding-left: 5%;
    letter-spacing: 1px;
    z-index: 3;
}

.input-ddd:hover {
    width: 98%;
    border: 1px solid #8e69ff;
}

.input-city {
    width: 98%;
    border: 1px solid #cab8ff;
    color: #4925b3;
    font-weight: 600;
    border-radius: 10px;
}

.input-city::placeholder {
    font-weight: 600;
    opacity: 1;
    color: #cab8ff;
    font-size: 10px;
    padding-left: 5%;
    letter-spacing: 1px;
    z-index: 3;
}

.input-city:hover {
    width: 98%;
    border: 1px solid #8e69ff;
}

.container-phones {
    display: grid;
    grid-template-columns: 23% 44.5%;
}

.container-city {
    display: grid;
    grid-template-columns: 73% 10%;
}

.content-payment-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: start; */
    width: 90%;
    height: 450px;
    background-color: #442b90;
    padding: 10px;
    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin-left: -10%;
}

.title-payment-data {
    font-size: 22px;
    font-weight: 600;
    color: white;
    margin-bottom: 10%;
}

.container-radio-button {
    width: 100%;
    margin-left: -25%;
    margin-bottom: 5%;
    color: white;
}

.container-resume {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #e2d7ff;
    margin-bottom: 10%;
    margin-top: -3%;
}

.container-expiration-date {
    display: grid;
    grid-template-columns: 30% 30% 33%;
    column-gap: 10px;
    justify-content: flex-start;
}

.inputs-payment-data { 
    background-color: white;
    margin-bottom: 2%;
    width: 100%;
    border-radius: 7px;
    padding: 3px 5px;
    color: black;
    font-weight: 600;
}

.inputs-payment-data-cvv { 
    background-color: white;
    margin-bottom: 2%;
    width: 100%;
    color: black;
    font-weight: 600;
}

.select-payment-data{ 
    background-color: transparent;
    width: 100%;
}

.inputs-payment-data-cvv::placeholder {
    font-weight: 600;
    opacity: 1;
    color: black;
    font-size: 10px;
    padding-left: 5%;
    letter-spacing: 1px;
    z-index: 3;
}

.inputs-payment-data::placeholder {
    font-weight: 600;
    opacity: 1;
    color: black;
    font-size: 10px;
    padding-left: 5%;
    letter-spacing: 1px;
    z-index: 3;
}

.payment-button-container {
    width: 100%;
    display: flex;
    align-items: end;
    height: 100%;
    margin-bottom: 5%;
}

.payment-button {
    width: 100%;
    background-color: white;
    color: #2D1674;
    font-weight: 600;
    padding: 5px;
    border: none;
    border-radius: 5px;
}

.payment-button:hover {
    width: 100%;
    background-color: #663be6;
    color: white;
    font-weight: 600;
    padding: 5px;
    border: none;
    border-radius: 5px;
    transition: background-color 0.5s ease-out 1ms;
    /* transition: color 0.5s ease-out 1ms */
}

.text-developed-payment {
    font-size: 12px;
    margin-right: 4px;
    color: #663be6;
    font-family: 'poppins';
}

.container-type-person {
    margin-bottom: 5%;
}
.text-type-person {
    margin-left: -30%;
    color: #cab8ff;
    font-size: 14px;
    font-weight: 500;
}

.content-type-person {
    color: #7c53f8;
}

:where(.css-dev-only-do-not-override-zjzpde).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background-color: #2D1674;
    border-color: #2D1674;
}

.container-finish-payment {
    width: 70%;
    margin: auto;
    margin-top: 8%;
}

.title-finish-payment {
    font-size: 20px;
    color: #2D1674;
    font-weight: 600;
    margin-bottom: 2%;
}

.container-loader {
    margin-top: 10%;
}

.container-loader-button {
    width: 20px;
}

.payment-date {
    font-weight: 700;
    color: #663be6;
}

.text-info-value {
    font-size: 10px;
    color: #fffd7e;
}

@media only screen and (max-width: 800px ) {
    .content-payment {
        width: 90%;
        display: flex;
        flex-direction: column;
        /* grid-template-columns: 32% 30%; */
        /* align-content: center;
        justify-content: center; */
        margin: auto;
        margin-top: 2%;
    }
   
    .content-person {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        width: 100%;
        box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        padding: 0px 20px;
        height: 485px;
        margin-top: 3%;
    }

    .content-payment-data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* align-items: start; */
        width: 100%;
        height: 450px;
        background-color: #442b90;
        padding: 10px;
        box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        margin-left: 0%;
        margin-top: -0.5%;
    }

   
    .container-secure-pay {
        width: 90%;
        display: flex;
        justify-content: flex-end;
        margin-top: 15%;
        margin-bottom: -3%;
    }

    .img {
        width: 50px;
        height: 50px;
        margin-top: 10%;
    }

    .container-phones {
        display: grid;
        grid-template-columns: 23% 77%;
    }

    .input-ddd {
        width: 95%;
        border: 1px solid #cab8ff;
        color: #4925b3;
        font-weight: 600;
    }

    .container-city {
        display: grid;
        grid-template-columns: 80% 20%;
    }

    .input-city {
        width: 99%;
        border: 1px solid #cab8ff;
        color: #4925b3;
        font-weight: 600;
    }

    .inputs-content {
        background-color: transparent;
        margin-bottom: 2%;
        width: 100%;
        border-radius: 7px;
        padding: 3px 5px;
        border: 1px solid #cab8ff;
        color: #4925b3;
        font-weight: 600;
    }

    .inputs-content:hover {
        width: 100%;
        margin-bottom: 2%;
        border: 1px solid #8e69ff;
    }

    .container-inputs-content {
        width: 100%;
    }
}
