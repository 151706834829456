@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.container-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 105vh;
    background: linear-gradient(43deg, #C7C5F4  0%, #5850ca 100%);
}

.card-login {
    width: 80%;
    height: 40%;
    background-color: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 10px 20px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.text-login {
    margin-top: -5%;
    margin-bottom: 2%;
    letter-spacing: 2px;
    color: rgba(249,141,1,1);
    font-weight: 400;
    font-size: 25px;
}

.container-inputs-button {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inputs-style {
    width: 85%;
    margin-bottom: 10px;
    padding: 5px;
    padding-left: 3%;
    border-radius: 20px;
    font-family: 'Poppins', sans-serif;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
    border: none;
}

input::placeholder  {
    font-weight: bold;
    opacity: .7;
    color: #2D1674;
    font-size: 10px;
    padding-left: 5%;
    letter-spacing: 1px;
    z-index: 3;
}

input:focus{
    outline: 1px solid #2D1674;
}

input {
    color: #2D1674;
}

.button-style-login {
    width: 86.5%;
    padding: 5px;
    background: rgb(249,141,1);
    background: #2D1674;
    border: none;
    border-radius: 20px;
    color: white;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    cursor: pointer;
}

.text-or{
    margin-block-start: 1em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-size: 9px;
    color: #14004d;
    font-weight: 600;
}

.text-register-new{
    margin-block-start: 0.5em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-size: 12px;
    color: #2D1674;
    font-weight: bold;
    letter-spacing: 2.5px;
    cursor: pointer;
    font-family: 'poppins';

}

.text-forgot{
    margin-block-start: 1.5em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-size: 10px;
    color: #2D1674;
    font-weight: 700;
    letter-spacing: 2.5px;
    cursor: pointer;
    font-family: 'poppins';
}

.text-return-login{
    margin-block-start: 3.5em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-size: 10px;
    color: #2D1674;
    font-weight: 700;
    letter-spacing: 2.5px;
    cursor: pointer;
    font-family: 'poppins';
}

.container-data-enterprise{
    display: flex;
    margin-top: 5%;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.text-developed {
    font-size: 12px;
    margin-right: 4px;
    color: white;
    font-family: 'poppins';
}

.text-name-company {
    color: #2D1674;
    font-weight: 700;
    font-family: 'poppins';
}


.body-login {
	/* background: linear-gradient(90deg, #C7C5F4, #5850ca); */
    min-height: 100vh;	
    margin: auto;
    display: flex;
	align-items: center;
	justify-content: center;
    flex-direction: column;
}

.container {
    margin: auto;
}

.title-card-form {
    margin-bottom: 25px;
    color: #14004d;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    font-size: 19px;
}

.screen {		
	background: linear-gradient(163deg, #463aa1 30%, #7C78B8 100%);		
	position: relative;	
	height: 450px;
	width: 380px;	
	box-shadow: 0px 0px 24px #5C5696;
    margin-top: 9%;
    border-radius: 5px;
}

.container-card-login {
    margin-top: -2%;
}

.screen__content {
	z-index: 1;
	position: relative;	
	height: 100%;
    margin-top: -5%;
}

.screen__background {		
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	-webkit-clip-path: inset(0 0 0 0);
	clip-path: inset(0 0 0 0);	
}

.screen__background__shape {
	transform: rotate(45deg);
	position: absolute;
}

.screen__background__shape1 {
	height: 520px;
	width: 520px;
	background: #FFF;	
	top: -50px;
	right: 120px;	
	border-radius: 0 72px 0 0;
}

.screen__background__shape2 {
	height: 220px;
	width: 220px;
	background: #6C63AC;	
	top: -172px;
	right: 0;	
	border-radius: 32px;
}

.screen__background__shape3 {
	height: 540px;
	width: 190px;
	background: linear-gradient(270deg, #5D54A4, #6A679E);
	top: -24px;
	right: 0;	
	border-radius: 32px;
}

.screen__background__shape4 {
	height: 400px;
	width: 200px;
	background: #7E7BB9;	
	top: 420px;
	right: 50px;	
	border-radius: 60px;
}

.login {
	width: 320px;
	padding: 30px;
	padding-top: 76px;
}

.login__field {
	padding: 20px 0px;	
	position: relative;	
}

.login__icon {
	position: absolute;
	top: 30px;
	color: #7875B5;
}

.login__icon_eye {
	position: absolute;
	top: 30px;
    right: 40px;
	color: #7875B5;
    font-size: 13px;
    cursor: pointer;
}

.login__input {
	border: none;
	border-bottom: 2px solid #D1D1D4;
	background: none;
	padding: 10px;
	padding-left: 24px;
	font-weight: 700;
	width: 85%;
	transition: .2s;
    font-family: 'poppins';
}

.login__input:active,
.login__input:focus,
.login__input:hover {
	outline: none;
	border-bottom-color: #6A679E;
}

.login__submit {
	background: #fff;
	font-size: 12px;
	margin-top: 30px;
	padding: 10px 20px;
	border-radius: 26px;
	border: 1px solid #D4D3E8;
	text-transform: uppercase;
	font-weight: 700;
	display: flex;
	align-items: center;
	width: 100%;
	color: #4C489D;
	box-shadow: 0px 2px 2px #5C5696;
	cursor: pointer;
	transition: .2s;
    font-family: 'poppins';
    font-weight: 900;
    letter-spacing: 1.5px;
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
	border-color: #6A679E;
	outline: none;
}

.button__icon {
	font-size: 24px;
	margin-left: auto;
	color: #7875B5;
}

.social-login {	
	position: absolute;
	height: 140px;
	width: 160px;
	text-align: center;
	bottom: 0px;
	right: 0px;
	color: #fff;
    font-size: 14px;
    font-family: 'poppins';
}

.social-icons {
	display: flex;
	align-items: center;
	justify-content: center;
}

.social-login__icon {
	padding: 20px 10px;
	color: #fff;
	text-decoration: none;	
	text-shadow: 0px 0px 8px #7875B5;
}

.social-login__icon:hover {
	transform: scale(1.5);	
}

.container-logo {
    /* margin-bottom: 1%; */
}

/* responsive */

@media only screen and (min-width: 680px ) {
    .card-login {
        width: 50%;
        height: 40%;
        background-color: white;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 10px 20px 1px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }
  }

  @media only screen and (max-width: 800px ) {
    .container-data-enterprise{
        display: flex;
        margin-top: 10%;
        align-items: center;
        width: 100%;
        justify-content: center;
    }

    .login {
        width: 320px;
        padding: 30px;
        padding-top: 66px;
    }

    .screen {		
        background: linear-gradient(90deg, #5D54A4, #443da9);		
        position: relative;	
        height: 400px;
        width: 300px;	
        box-shadow: 0px 0px 24px #5C5696;
        margin-top:40%;
        border-radius: 5px;
    }

    .container-card-login {
        margin-top: -20%;
        margin-left: -3%;
    }

    .login__input {
        border: none;
        border-bottom: 2px solid #D1D1D4;
        background: none;
        padding: 10px;
        padding-left: 24px;
        font-weight: 700;
        width: 70%;
        transition: .2s;
        font-family: 'poppins';
    }
  }

  @media only screen and (min-width: 1300px ) {
    .card-login {
        width: 25%;
        height: 40%;
        background-color: white;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 10px 20px 1px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }

    .button-style {
        width: 75%;
        padding: 5px;
        background: rgb(249,141,1);
        background: linear-gradient(90deg, rgba(249,141,1,1) 35%, rgba(255,201,150,1) 100%);
        border: none;
        border-radius: 20px;
        color: white;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 1px;
        cursor: pointer;
    }

    /* .screen {		
        background: linear-gradient(90deg, #5D54A4, #7C78B8);		
        position: relative;	
        height: 500px;
        width: 400px;	
        box-shadow: 0px 0px 24px #5C5696;
        margin-top:40%;
        border-radius: 5px;
    } */

    
  }



