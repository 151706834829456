.container-entrance {
    width: 100%;
}

.content-entrance {
    width: 80%;
    margin: auto;
    margin-top: 4%;
}

.container-inputs-entrance {
    width: 80%;
    margin-bottom: 2%;
    margin-right: 1%;
}

.input-style-entrance {
    width: 100%;
    padding: 5px;
    padding-left: 3%;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    /* box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1); */
    border: 1px solid #2D1674;
}

.container-total-value-entrance {
    display: flex;
    width: 55%;
    justify-content: space-between;
    margin-bottom: 2%;
}

.total-values-entrance {
    border: 2px solid #2D1674;
    color: #2D1674;
    padding: 3px 15px;
    border-radius: 20px;
    font-weight: 700;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.total-sales-entrance {
    border: 2px solid #8C52FF;
    color: #8C52FF;
    padding: 3px 15px;
    border-radius: 20px;
    font-weight: 700;
    margin-right: 10px;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.container-search-entrance {
    display: flex;
    justify-content: space-between;
    align-items:start;
    width: 55%;
    margin-bottom: 3%;
}

.container-total-value-sales {
    display: flex;
}

.total-sales {
    border: 2px solid rgb(255, 162, 39);
    color: rgb(255, 162, 39);
    padding: 3px 15px;
    border-radius: 20px;
    font-weight: 700;
    margin-right: 10px;
}

.container-table-entrance {
    width: 55%;
}

.container-button-entrance{
    width: 60%;
    margin-top: 0%;
}

.button-style-entrance {
    width: 100%;
    padding: 7px 8px;
    background: #2D1674;
    border: none;
    border-radius: 7px;
    font-size: 13px;
    color: white;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    cursor: pointer;
}

@media only screen and (max-width: 800px ) {
    .container-entrance {
        width: 100%;
        margin-bottom: 10%;
        margin-top: 30%;
        margin-left: -12%;
    }

    .content-entrance {
        width: 100%;
        margin: auto;
        margin-top: 4%;
    }
    
    .container-total-value-entrance {
        display: flex;
        flex-direction: column;
        margin-bottom: 10%;
        width: 100%;
    }

    .container-table-entrance {
        width: 100%;
    }

    .container-inputs-entrance {
        width: 100%;
        margin-bottom: 2%;
    }

    .container-total-value-sales {
        display: flex;
        width: 100%;
    }

    .container-search-entrance {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
    }

    .total-values-entrance {
        border: 2px solid #2D1674;
        color: #2D1674;
        padding: 0px 15px;
        border-radius: 20px;
        font-weight: 700;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
    
    .total-sales-entrance {
        border: 2px solid #8C52FF;
        color: #8C52FF;
        padding: 0px 15px;
        border-radius: 20px;
        font-weight: 700;
        margin-right: 10px;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }

    .container-button-entrance{
        width: 100%;
        margin-bottom: 10%;
    }
}
