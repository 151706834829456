@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.container-plan {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    font-family: 'poppins';
    width: 100%;
}

.title-plan {
    font-size: 24px;
    margin-bottom: 5%;
}

.container-card-plan {
    width: 700px;
    /* From https://css.glass */
    background: white;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid #4a26b7;
    padding: 20px 15px;
}

.container-info-plan {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.container-title-sections {
    display: flex;
    justify-content: space-between;
}

.button-back-main {
    color: #4a26b7;
    font-weight: 600;
    cursor: pointer;
}

.button-action {
    color: #4a26b7;
    font-weight: 700;
    cursor: pointer;
}

.button-action:hover {
    color: #835cf7;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.2s !important;
}

.button-action-cancel {
    color: #2D1674;
    font-weight: 700;
    cursor: pointer;
}

.button-action-cancel:hover {
    color: #ef5e5e;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.2s !important;
}

.container-alter-plan {
    border: 1px solid #2D1674;
    border-radius: 10px;
    padding: 8px;
    margin-bottom: 10px;
    box-shadow: 0px 1px 1px 1px rgba(95, 95, 95, 0.5);
}

.container-alter-plan-embreve {
    border: 1px solid #2D1674;
    background-color: #c6c6c6;
    border-radius: 10px;
    padding: 8px;
    margin-bottom: 10px;
    /* box-shadow: 0px 1px 1px 1px rgba(95, 95, 95, 0.5); */
}

.description-plan {
    font-size: 13px;
    color: #230049;
    font-weight: 600;
}

.button-action-embreve {
    color: #393939;
    font-weight: 700;
    /* cursor: pointer; */
}

.inputs-alter-payment-data { 
    margin-bottom: 1%;
    width: 100%;
    border-radius: 7px;
    padding: 3px 5px;
    color: rgb(163, 163, 163)c0;;
    font-weight: 600;
    /* margin-top: 3px; */
    border: 1px solid #c0c0c0;
}

.alter-payment-button {
    width: 100%;
    background-color: #2D1674;
    color: white;
    font-weight: 600;
    padding: 5px;
    border: none;
    border-radius: 5px;
}

.alter-payment-button:hover {
    width: 100%;
    background-color: #663be6;
    color: white;
    font-weight: 600;
    padding: 5px;
    border: none;
    border-radius: 5px;
    transition: background-color 0.5s ease-out 1ms;
    /* transition: color 0.5s ease-out 1ms */
}

@media only screen and (max-width: 800px ) {
    .container-plan {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 25%;
        font-family: 'poppins';
        width: 100%;
        margin-left: -15%;
    }

    .title-plan {
        font-size: 24px;
        margin-bottom: 15%;
    }

    .container-card-plan {
        width: 110%;
        /* From https://css.glass */
        background: white;
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid #4a26b7;
        padding: 20px 15px;
        font-size: 14px;
    }

}



