.container-details {
    width: 100%;
}

.content-details {
    width: 80%;
    margin: auto;
    margin-top: 4%;
}

.container-card-details {
    display: grid;
    grid-template-columns: 40% 40% 40%;
    gap: 10px;
    width:60%;
    margin-top: 10%;
}

.container-search-detail {
    width: 60%;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
}

.container-inputs-sales {
    width: 80%;
    margin-bottom: 2%;
    margin-right: 1%;

}

.input-style-details {
    width: 100%;
    padding: 5px;
    padding-left: 3%;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    border: 1px solid #2D1674;
}

.card-details {
    background-color: #fab978;
    padding: 5px;
}

.content-card-details {
    text-align: center;
    display: flex;
    flex-direction: column;
    color: white;
}

.container-button-details{
    width: 20%;
}


.button-style-details {
    width: 100%;
    padding: 7px 8px;
    background: #2D1674;
    border: none;
    border-radius: 7px;
    font-size: 13px;
    color: white;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    cursor: pointer;
}

.input-style-exit {
    width: 100%;
    padding: 5px;
    padding-left: 3%;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    /* box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1); */
    border: 1px solid #2D1674;
}

@media only screen and (max-width: 800px ) {
    .container-details {
        width: 100%;
        margin-top: 30%;
    }
    .content-details {
        width: 100%;
        margin: auto;
        margin-top: 4%;
    }

    .container-card-details {
        display: flex;
        width:100%;
        flex-direction: column;
        margin-left: -2%;
        margin-top: 20%;
    }

    .container-search-sales {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
    }

    .container-inputs-sales {
        width: 80%;
        margin-bottom: 2%;
    }

    .container-button-details{
        width: 80%;
    }

    .input-date-details {
        width: 80%;
        padding: 5px;
        padding-left: 3%;
        border-radius: 10px;
        font-family: 'Poppins', sans-serif;
        /* box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1); */
        border: 1px solid #2D1674;
    }

    .container-search-detail {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
    }
    
    .input-style-exit {
        width: 80%;
        padding: 5px;
        padding-left: 3%;
        border-radius: 10px;
        font-family: 'Poppins', sans-serif;
        /* box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1); */
        border: 1px solid #2D1674;
    }

}