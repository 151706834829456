.container-home {
    width: 100%;
}

.container-card-home {
    display: grid;
    grid-template-columns: 40% 40%;
    gap: 10px;
    width:70%;
    margin: auto;
    margin-top: 10%;
}

@media only screen and (max-width: 800px ) {
    .container-card-home {
        display: flex;
       flex-direction: column;
       margin-top: 50%;
       width: 100%;
    }
}