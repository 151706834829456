@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

.container{
    /* width: 100%;
    height: 100vh;
    background: white;
    font-family: 'Poppins', sans-serif; */
}

.container-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.card-login {
    width: 80%;
    height: 40%;
    background-color: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 10px 20px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.text-login {
    margin-top: -5%;
    margin-bottom: 2%;
    letter-spacing: 2px;
    color: #2D1674;
    font-weight: 400;
    font-size: 25px;
}

.container-inputs-button {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inputs-style-signup {
    width: 85%;
    margin-bottom: 10px;
    padding: 5px;
    padding-left: 3%;
    border-radius: 20px;
    font-family: 'Poppins', sans-serif;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
    border: none;
}

/* input::placeholder {
    font-weight: bold;
    opacity: .5;
    color: #8C52FF;
    font-size: 10px;
    padding-left: 5%;
    letter-spacing: 1px;
    z-index: -1;
} */

.button-style-signup {
    width: 86.5%;
    padding: 5px;
    background: #2D1674;
    border: none;
    border-radius: 20px;
    color: white;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    cursor: pointer;
}

.text-or{
    margin-block-start: 1em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-size: 9px;
}

.text-register-new{
    margin-block-start: 0.5em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-size: 12px;
    color: #2D1674;
    font-weight: 700;
    letter-spacing: 2.5px;
    cursor: pointer;
}

.login__field-signup {
	padding: 10px 0px;	
	position: relative;	
}

.login-signup {
	width: 320px;
	padding: 30px;
	padding-top: 46px;
}

/* responsive */

@media only screen and (min-width: 680px ) {
    .card-login {
        width: 50%;
        height: 40%;
        background-color: white;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 10px 20px 1px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }
  }

  @media only screen and (min-width: 1300px ) {
    .card-login {
        width: 25%;
        height: 40%;
        background-color: white;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 10px 20px 1px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }

    .button-style {
        width: 75%;
        padding: 5px;
        background: rgb(249,141,1);
        background: linear-gradient(90deg, rgba(249,141,1,1) 35%, rgba(255,201,150,1) 100%);
        border: none;
        border-radius: 20px;
        color: white;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 1px;
        cursor: pointer;
    }
  }



