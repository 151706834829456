@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.container-plan {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    font-family: 'poppins';
    width: 100%;
}

.card-profile {
    width: 30%;
    background: white;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px 15px;
}

.container-image-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 0px 30px 0px;
}

.image-profile {
    height: 70px;
    width: 70px;
    background: rgb(97,41,217);
    background: linear-gradient(218deg, rgba(97,41,217,1) 1%, rgba(198,157,245,1) 100%);
    border-radius: 50%;
    color: white;
    font-weight: 700;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.186);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 26px;
}


.container-content-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container-edit-company-name {
    display: grid;
    grid-template-columns: 70% 10%;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.container-icon-edit {
    cursor: pointer;
    font-size: 10px;
    margin-left: 10px;
}

.container-icons-ilustration {
    width: 80%;
}
.icons-ilustration {
    margin-right: 5%;
}

.input-style-edit-profile {
    width: 80%;
    padding: 0px;
    padding-left: 3%;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    /* box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1); */
    border: 1px solid #2D1674;
    margin-right: 5%;
}

.container-icons-edit {
    width: 100%;
    display: grid;
    grid-template-columns: 80% 10%;
    margin-left: -50%;
}
.style-icon-check-profile {
    font-size: 15px;
    color: rgb(123, 255, 108);
    margin-right: 5px;
}

.style-icon-cancel-profile {
    font-size: 15px;
    color: rgb(255, 108, 108);
}

@media only screen and (max-width: 800px ) {
    .container-plan {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 25%;
        font-family: 'poppins';
        width: 100%;
        margin-left: -15%;
    }

    .card-profile {
        width: 95%;
        background: white;
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        padding: 20px 15px;
    }
   
}



