.table-container-analysis {
    width: 65%;
    margin-top: 5%;
    text-align: center;
}

@media only screen and (max-width: 800px ) {
    .table-container-analysis {
        width: 100%;
        margin-top: 5%;
        text-align: center;
    }
}