.container-controllers {
    width: 100%;
}

.container-card-controllers {
    display: grid;
    grid-template-columns: 40% 40% 40%;
    gap: 10px;
    width:60%;
    /* margin: auto; */
    margin-left: 15%;
    margin-top: 15%;
}

@media only screen and (max-width: 800px ) {
    .container-card-controllers {
        display: flex;
        width:100%;
        flex-direction: column;
        margin-left: -2%;
        margin-top: 40%;
    }
    
}