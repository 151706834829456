@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');
.container-new-scheduling {
    display: flex;
    justify-content: end;
    width: 98%;
    margin-top: 4%;
    font-family: 'poppins';
}

.container-calendar {
    width: '100px';
}

.titles-calendar {
    color: #676767;
    font-weight: 600;
    font-size: 16px;
}

.select-hours {
    width: 160%;
    padding: 0px;
    padding-left: 3%;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    border: 1px solid #2D1674;
    margin-right: 5%;
    font-size: 12.5px;
    text-align: start;
}

.container-select-hours {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 5%;
    font-family: 'poppins'
}

.container-hours-calendar {
    margin-top: 5%;
}

.container-content-calendar {
    padding: 30px 30px;
}

.title-hours {
    margin-bottom: 10px;
    font-size: 17px;
    font-family: 'poppins'
}

.titles-config {
    margin-bottom: 10px;
    font-size: 17px;
    margin-top: 5%;
}

.box-hours { 
    width: 100%;
}

.container-footer-schedule {
    height: 28%;
    display: flex;
    margin-top: 65%;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
}

.color-title-resume {
    color: #5632c0
}

.container-resume-dates {
    margin-top: 15%;
}

.card-resume-dates {
    padding: 10px;
    border: 1px solid #2D1674;
    border-radius: 10px;
}

.container-loading-calendar {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
}

.container-button-register-calendar{
    display: flex;
    align-items: center;
    width: 25%;
    margin-top: 1%;
}

.button-style-register-sche {
    width: 100%;
    padding: 7px 8px;
    background: #2D1674;
    border: none;
    border-radius: 20px;
    font-size: 13px;
    color: white;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    cursor: pointer;
}

.container-modal {
    margin-top: 120%;
}

.button-style-block-confirm {
    width: 20%;
    padding: 7px 8px;
    background: #2D1674;
    border: none;
    border-radius: 20px;
    font-size: 13px;
    color: white;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    cursor: pointer;
}

.button-style-block-confirm:hover {
    padding: 7px 8px;
    background: #4e2db1;
    border: none;
    border-radius: 20px;
    font-size: 13px;
    color: white;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    cursor: pointer;
}

.button-style-block-cancel {
    padding: 7px 8px;
    background: #ff5353;
    border: none;
    border-radius: 20px;
    font-size: 13px;
    color: white;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    cursor: pointer;
}

.button-style-block-cancel:hover {
    padding: 7px 8px;
    background: #de2626;
    border: none;
    border-radius: 20px;
    font-size: 13px;
    color: white;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    cursor: pointer;
}

.button-style-delete-confirm {
    width: 25%;
    padding: 7px 8px;
    background: #2D1674;
    border: none;
    border-radius: 20px;
    font-size: 13px;
    color: white;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    cursor: pointer;
}


.button-style-delete-cancel {
    width: 20%;
    padding: 7px 8px;
    background: #ff5353;
    border: none;
    border-radius: 20px;
    font-size: 13px;
    color: white;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    cursor: pointer;
}

.title-day-name-calendar {
    font-size: 18px;
    color: #2D1674;
    font-weight: 500;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.title-day-name-selected-calendar {
    font-size: 18px;
    color: white;
    font-weight: 500;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.title-day-number-calendar {
    font-size: 16px;
    color: #2D1674;
    font-weight: 500;
    margin-top: 3px;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.title-day-number-selected-calendar {
    font-size: 16px;
    color: white;
    font-weight: 500;
    margin-top: 3px;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.button-style-calendar {
    width: 100%;
    padding: 7px 8px;
    background: #2D1674;
    border: none;
    border-radius: 20px;
    font-size: 13px;
    color: white;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    cursor: pointer;
}

.button-style-calendar:hover {
    background: rgba(45, 22, 116, 0.9)
}

/* ------------------------------------------------------ */

.container-card-calendar-days {
    display: flex;
    width: 100%;
    overflow-x: scroll; 
    overflow-y: hidden;
    white-space: nowrap;
    margin-top: 5%;
}

.container-card-calendar-days::-webkit-scrollbar {
    width: 3px;
    height: 6px; /* A altura só é vista quando a rolagem é horizontal */

  }
  
  .container-card-calendar-days::-webkit-scrollbar-track {
    background: transparent;
    padding: 12px;
    margin-top: 10px;
  }
  
  .container-card-calendar-days::-webkit-scrollbar-thumb {
    background-color: #c7b4ff;
    border-radius: 10px;
    margin-top: 5%;
    
  }

.card-days-calendar {
    border: solid 1px #2D1674 ;
    padding: 6px;
    border-radius: 10px;
    width: 95%;
    margin-right: 13px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
}

.card-days-calendar-selected {
    background-color: #2D1674;
    border: solid 1px #2D1674 ;
    padding: 6px;
    color: white;
    border-radius: 10px;
    width: 95%;
    margin-right: 13px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
}

.container-hours-calendar-card {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    row-gap: 10px;
    margin-top: 3%;
}

.card-hours-calendar {
    color: #2D1674;
    border: 1px solid #2D1674;
    padding: 10px;
    border-radius: 10px;
    margin-right: 4px;
    cursor: pointer;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.card-hours-calendar-selected {
    color: white;
    background-color: #6e6e6e;
    border: 1px solid #6e6e6e;
    padding: 10px;
    border-radius: 10px;
    margin-right: 4px;
    cursor: pointer;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

p{
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.text-dont-hours-block { 
    margin-top: 5%;
    font-size: 22px;
    color: #2D1674;
}

.container-switch-blocked {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title-config {
    font-size: 20px;
}

.switch {
    width: 24%;
}

.title-desblocked {
    /* font-weight: 700; */
    color: #4e2db1;
    font-weight: 600;
    font-size: 22px;
}

@media only screen and (max-width: 800px ) {
    .container-new-scheduling {
        display: flex;
        justify-content: end;
        width: 98%;
        margin-top: 20%;
    }

    .container-calendar {
        height: '600px';
        width: '80%';
        display: 'flex';
        justify-content:'center';
    }

    .button-style-register-sche {
        width: 100%;
        padding: 7px 8px;
        background: #2D1674;
        border: none;
        border-radius: 20px;
        font-size: 9px;
        color: white;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 1px;
        cursor: pointer;
    }

    .container-new-scheduling {
        /* display: flex; */
        /* justify-content: end; */
        /* width: 100%;
        margin-top: 4%; */
    }

    .container-button-register-calendar{
        width: 80%;
        margin-top: 1%;
        margin-bottom: 15%;
    }

    .container-responsive-calendar {
        margin-left: -18%;
        height: 800px;
    }

    .container-hours-calendar-card {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        row-gap: 10px;
        margin-top: 3%;
    }

    .button-style-block-confirm {
        width: 100%;
        padding: 7px 8px;
        background: #2D1674;
        border: none;
        border-radius: 20px;
        font-size: 13px;
        color: white;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 1px;
        cursor: pointer;
    }

    .button-style-block-cancel {
        width: 100%;
        padding: 7px 8px;
        background: #ff5353;
        border: none;
        border-radius: 20px;
        font-size: 13px;
        color: white;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 1px;
        cursor: pointer;
    }

    .title-config {
        font-size: 15px;
    }

    .switch {
        width: 29%;
    }

    .button-style-delete-confirm {
        width: 100%;
        padding: 7px 8px;
        background: #2D1674;
        border: none;
        border-radius: 20px;
        font-size: 13px;
        color: white;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 1px;
        cursor: pointer;
    }

    .button-style-delete-cancel {
        width: 100%;
        padding: 7px 8px;
        background: #ff5353;
        border: none;
        border-radius: 20px;
        font-size: 13px;
        color: white;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 1px;
        cursor: pointer;
    }
}

/* ESTILIZAÇÃO DA PAGINA DO CLIENTE */



