.container-menu {
    min-height: 100vh;
    height: 100%;
    background: #2D1674;
    /* background: linear-gradient(180deg, rgba(249,141,1,1) 2%, rgba(255,168,86,1) 100%); */
    color: white;
    position: fixed;
    z-index: 2;
    font-family: 'poppins';
    font-size: 14px;
}

.container-logo {
    padding-left: 5%;
    padding-top: 8%;
    padding-bottom: 20%;
    background: #2D1674;
    letter-spacing: 3px;
    text-transform: uppercase;
    cursor: pointer;
}

.container-submenu {
    /* color: rgb(255, 203, 154); */
    margin-left: 5%;
    margin-top: 8%;
}

.container-menu-items { 
    display: flex;
    /* margin-top: 10%; */
    margin-left: 0%;
    height: 100%;
    cursor: pointer;
    color:white 
}

.container-menu-items:hover{
    color:white ;
    font-weight: 600;
    transition: all 0.1s !important;
}

.style-icons {
    margin-right: 2%;
}

.container-logout {
    display: flex;
    height: 25vw;
    align-items: flex-end;
    cursor: pointer;
    color: white;
    margin-left: 4%;
}

.container-hamburguer {
    margin-top: 25%;
    margin-left: 15%;
}

.container-menus-gestao {
    margin-left: 5%;
}
