@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

:root {
    --main-color: #2d1674;
    --title-color: #ffffff;
    --time-color: #2d1674;
    --button-color: #794cff;
    --text-color: #333;
}

.container-schedule {
    padding: 15px;
    margin-top: 10%;
}
.container-card-days {
    display: flex;
    width: 80%;
    overflow-x: scroll; 
    overflow-y: hidden;
    white-space: nowrap;
    margin-top: 1%;
}

.card-days {
    border: solid 1px var(--main-color) ;
    padding: 6px;
    border-radius: 10px;
    width: 8%;
    margin-right: 13px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
     
}

.title-day-name {
    font-size: 18px;
    color: var(--main-color);
    font-weight: 500;
}

.title-day-number {
    font-size: 16px;
    color: var(--main-color);
    font-weight: 700;
    margin-top: 3px;
}

.title-year-month {
    color: var(--main-color);
    margin-top: 3px;
}

.title-products-resume {
    font-size: 16px;
    color: var(--main-color);
}

.titles-resume {
    color: #676767;
    font-weight: 600;
    font-size: 16px;
}

@media only screen and (max-width: 800px ) {
    .container-card-days {
        display: flex;
        width: 95%;
        overflow-x: scroll; 
        overflow-y: hidden;
        white-space: nowrap;
        margin-top: 5%;
    }

    .card-days {
        border: solid 1px var(--main-color) ;
        padding: 6px;
        border-radius: 10px;
        width: 150px;
        margin-right: 13px;
        text-align: center;
        cursor: pointer;
        box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
    }
    .card-days-selected {
        background-color: var(--main-color);
        border: solid 1px var(--main-color) ;
        padding: 6px;
        color: white;
        border-radius: 10px;
        width: 150px;
        margin-right: 13px;
        text-align: center;
        cursor: pointer;
        box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
    }

    .title-day-name-selected {
        font-size: 18px;
        color: white;
        font-weight: 500;
    }
    
    .title-day-number-selected {
        font-size: 16px;
        color: white;
        font-weight: 700;
        margin-top: 3px;
    }
    
    .title-year-month-selected {
        color: white;
        margin-top: 3px;
    }

    .container-hours {
        margin-top: 10%;
        margin-bottom: 10%;
    }

    .container-hours-card {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        row-gap: 10px;
        margin-top: 3%;
    }

    .card-hours {
        color: var(--main-color);
        border: 1px solid var(--main-color);
        padding: 10px;
        border-radius: 10px;
        margin-right: 4px;
        
    }

    .card-hours-selected {
        color: white;
        background-color: var(--main-color);
        border: 1px solid var(--main-color);
        padding: 10px;
        border-radius: 10px;
        margin-right: 4px;
    }

    .container-products { 
        width: 100%;
        margin-bottom: 20%;
    }

    .card-products-schedule {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 25% 75%;
        box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        margin-bottom: 10px;
        margin-top: 5%;
    }

    .card-products-schedule-finish {
        width: 100%;
        box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 10px;
        margin-top: 5%;
    }

    .card-products-schedule-selected {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 25% 75%;
        box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
        background-color: var(--main-color);
        border-radius: 5px;
        margin-bottom: 10px;
        margin-top: 5%;
    }

    .container-product-saleprice {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
        margin-bottom: 3%;
        padding: 10px;
    }

    .container-price-name {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: start;
        padding-bottom: 5%;
    }

    .container-image-product {
        width: 100%;
        height: 100%;
        border-radius: 5px 0px 0px 5px;
        object-fit: cover;
    }

    .title-name-product {
        font-size: 17px;
        font-weight: 700;
        color: black;
    }

    .title-name-product-selected {
        font-size: 17px;
        font-weight: 700;
        color: white;
    }

    .title-price-product {
        font-size: 17px;
        font-weight: 700;
        color: rgb(169, 213, 142);
        display: flex;
        justify-content: center;  
    }

    .title-duration {
        font-weight: 700;
        color: var(--main-color);
    }

    .title-duration-selected {
        font-weight: 700;
        color: var(--button-color);
    }

    .title-description { 
        color: white;
    }


    .container-button-scheduling {
        margin-top: 10%;
        margin-left: -1%;
    }

    .button-scheduling-blocked {
        width: 95%;
        padding: 15px 8px;
        background: #5f5f5f;
        border: none;
        border-radius: 10px;
        font-size: 13px;
        color: white;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 1px;
        cursor: not-allowed;
        position: fixed;
        bottom: 0;
    }

    .button-scheduling {
        width: 95%;
        padding: 15px 8px;
        background: var(--button-color);
        border: none;
        border-radius: 10px;
        font-size: 13px;
        color: white;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 1px;
        cursor: pointer;
        position: fixed;
        bottom: 0;
        /* left: 0; */
        /* right: auto; */
    }

    .button-finish-blocked {
        width: 100%;
        padding: 15px 8px;
        background: #5f5f5f;
        border: none;
        border-radius: 10px;
        font-size: 13px;
        color: white;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 1px;
        cursor: not-allowed;
    }

    .button-finish {
        width: 100%;
        padding: 15px 8px;
        background: var(--button-color);;
        border: none;
        border-radius: 10px;
        font-size: 13px;
        color: white;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 1px;
        cursor: pointer;
    }

    .container-total-resume {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .container-button-finish {
        height: 20vh;
        display: flex;
        align-items: flex-end;
        width: 100%;
        margin-bottom: 3%;
    }

    .button-scheduling-return {
        width: 100%;
        padding: 15px 8px;
        background: transparent;
        border: 1px solid #6d6d6d;
        border-radius: 10px;
        font-size: 13px;
        color: #6d6d6d;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 1px;
        cursor: pointer;
    }

    .container-message-info {
        display: flex;
        justify-content: center;
        margin-top: 5%;
    }

    .title-message-info {
        font-size: 16px;
        color: var(--main-color)
    }

    .title-message-info-not-hours {
        font-size: 22px;
        font-weight: 700;
        color: var(--main-color);
    }

    .container-data-person {
        margin-top: 5%;
        margin-bottom: 10%;
    }

    .box-data-person {
        margin-bottom: 3%;
    }

    .title-data-person {
        margin-bottom: 5px;
        font-size: 15px;
        color: #6d6d6d;
        font-weight: 700;
    }

    .title-emprevisto {
        color: red;
        font-size: 12.4px;
        font-weight: 700;
    }

    .container-congrats {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .title-congrats {
        font-size: 19px;
    }

    .container-cellphone {
        margin-top: 2%;
        display: flex;
    }

    .text-cellphone{ 
        font-size: 16px;
        text-align: start;
        color: var(--main-color);
    }

    .container-not-hours {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        height: 300px;
    }

    .text-not-hour-1 {
        color: var(--main-color);
        font-weight: 700;
        font-size: 18px;
    }

    .text-not-hour-2 {
        font-size: 13px;
    }

    .input-style-sche {
        width: 100%;
        padding: 5px;
        padding-left: 3%;
        border-radius: 10px;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 2%;
        /* box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1); */
        border: 1px solid var(--main-color);
        color: var(--main-color);
    }

    .input-style-sche::placeholder  {
        font-weight: bold;
        opacity: .7;
        color: var(--main-color);
        font-size: 10px;
        padding-left: 5%;
        letter-spacing: 1px;
        z-index: 3;
    }
}

@media only screen and (min-width: 810px ) and (max-width: 1800px) {
    .container-card-days {
        display: flex;
        width: 95%;
        overflow-x: scroll; 
        overflow-y: hidden;
        white-space: nowrap;
        margin-top: 1%;
    }

    .card-days {
        border: solid 1px var(--main-color) ;
        padding: 6px;
        border-radius: 10px;
        width: 150px;
        margin-right: 13px;
        text-align: center;
        cursor: pointer;
        box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
    }
    .card-days-selected {
        background-color: var(--main-color);
        border: solid 1px var(--main-color) ;
        padding: 6px;
        color: white;
        border-radius: 10px;
        width: 150px;
        margin-right: 13px;
        text-align: center;
        cursor: pointer;
        box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
    }

    .title-day-name-selected {
        font-size: 18px;
        color: white;
        font-weight: 500;
    }
    
    .title-day-number-selected {
        font-size: 16px;
        color: white;
        font-weight: 700;
        margin-top: 3px;
    }
    
    .title-year-month-selected {
        color: white;
        margin-top: 3px;
    }

    .container-hours {
        margin-top: 2%;
        margin-bottom: 1%;
    }

    .container-schedule {
        padding: 15px;
        margin-top: 1%;
    }

    .container-hours-card {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        row-gap: 10px;
        margin-top: 1%;
    }

    .card-hours {
        color: var(--main-color);
        border: 1px solid var(--main-color);
        padding: 10px;
        border-radius: 10px;
        margin-right: 4px;
        
    }

    .card-hours-selected {
        color: white;
        background-color: var(--main-color);
        border: 1px solid var(--main-color);
        padding: 10px;
        border-radius: 10px;
        margin-right: 4px;
    }

    .container-products { 
        width: 100%;
        margin-bottom: 20%;
    }

    .card-products-schedule {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 25% 75%;
        box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        margin-bottom: 10px;
        margin-top: 1%;
    }

    .card-products-schedule-finish {
        width: 100%;
        box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 10px;
        margin-top: 5%;
    }

    .card-products-schedule-selected {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 25% 75%;
        box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
        background-color: var(--main-color);
        border-radius: 5px;
        margin-bottom: 10px;
        margin-top: 5%;
    }

    .container-product-saleprice {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
        margin-bottom: 3%;
        padding: 10px;
    }

    .container-price-name {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: start;
        padding-bottom: 5%;
    }

    .container-image-product {
        width: 100%;
        height: 100%;
        border-radius: 5px 0px 0px 5px;
        object-fit: cover;
    }

    .title-name-product {
        font-size: 17px;
        font-weight: 700;
        color: black;
    }

    .title-name-product-selected {
        font-size: 17px;
        font-weight: 700;
        color: white;
    }

    .title-price-product {
        font-size: 17px;
        font-weight: 700;
        color: rgb(169, 213, 142);
        display: flex;
        justify-content: center;  
    }

    .title-duration {
        font-weight: 700;
        color: var(--main-color);
    }

    .title-duration-selected {
        font-weight: 700;
        color: var(--button-color);
    }

    .title-description { 
        color: white;
    }


    .container-button-scheduling {
        margin-top: 10%;
        margin-left: -1%;
    }

    .button-scheduling-blocked {
        width: 95%;
        padding: 15px 8px;
        background: #5f5f5f;
        border: none;
        border-radius: 10px;
        font-size: 13px;
        color: white;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 1px;
        cursor: not-allowed;
        position: fixed;
        bottom: 0;
    }

    .button-scheduling {
        width: 95%;
        padding: 15px 8px;
        background: var(--button-color);
        border: none;
        border-radius: 10px;
        font-size: 13px;
        color: white;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 1px;
        cursor: pointer;
        position: fixed;
        bottom: 0;
        /* left: 0; */
        /* right: auto; */
    }

    .button-finish-blocked {
        width: 100%;
        padding: 15px 8px;
        background: #5f5f5f;
        border: none;
        border-radius: 10px;
        font-size: 13px;
        color: white;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 1px;
        cursor: not-allowed;
    }

    .button-finish {
        width: 100%;
        padding: 15px 8px;
        background: var(--button-color);;
        border: none;
        border-radius: 10px;
        font-size: 13px;
        color: white;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 1px;
        cursor: pointer;
    }

    .container-total-resume {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .container-button-finish {
        height: 1vh;
        display: flex;
        align-items: flex-end;
        width: 100%;
        margin-bottom: 1%;
    }

    .button-scheduling-return {
        width: 100%;
        padding: 15px 8px;
        background: transparent;
        border: 1px solid #6d6d6d;
        border-radius: 10px;
        font-size: 13px;
        color: #6d6d6d;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 1px;
        cursor: pointer;
    }

    .container-message-info {
        display: flex;
        justify-content: center;
        margin-top: 5%;
    }

    .title-message-info {
        font-size: 16px;
        color: var(--main-color)
    }

    .title-message-info-not-hours {
        font-size: 22px;
        font-weight: 700;
        color: var(--main-color);
    }

    .container-data-person {
        margin-top: 2%;
        margin-bottom: 5%;
    }

    .box-data-person {
        margin-bottom: 1%;
    }

    .title-data-person {
        margin-bottom: 5px;
        font-size: 15px;
        color: #6d6d6d;
        font-weight: 700;
    }

    .title-emprevisto {
        color: red;
        font-size: 12.4px;
        font-weight: 700;
    }

    .container-congrats {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .title-congrats {
        font-size: 19px;
    }

    .container-cellphone {
        margin-top: 2%;
        display: flex;
    }

    .text-cellphone{ 
        font-size: 16px;
        text-align: start;
        color: var(--main-color);
    }

    .container-not-hours {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        height: 300px;
    }

    .text-not-hour-1 {
        color: var(--main-color);
        font-weight: 700;
        font-size: 18px;
    }

    .text-not-hour-2 {
        font-size: 13px;
    }

    .input-style-sche {
        width: 100%;
        padding: 5px;
        padding-left: 3%;
        border-radius: 10px;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 1%;
        /* box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1); */
        border: 1px solid var(--main-color);
        color: var(--main-color);
    }

    .input-style-sche::placeholder  {
        font-weight: bold;
        opacity: .7;
        color: var(--main-color);
        font-size: 10px;
        padding-left: 5%;
        letter-spacing: 1px;
        z-index: 3;
    }
}