.configure-avatar {
    cursor: pointer;
    color: #C9E265;
    text-transform: uppercase;
}

.container-avatar {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 2%;
    padding-right: 5%;
    text-transform: uppercase;
}

.text-exit {
    margin-left: 4%;
}