@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');
.container-tutorial {
    margin-right: 5%;
}

.container-tutorial-content-pause {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 20px;
    /* border: 1px solid ; */
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    background: rgb(45,22,116);
    background: linear-gradient(90deg, rgba(45,22,116,1) 19%, rgba(123,98,200,1) 100%);
    color: aliceblue;
    cursor: pointer;
    border-radius: 16px;
}

.container-tutorial-content-play {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 20px;
    /* border: 1px solid ; */
    background: rgb(41,217,105);
    background: linear-gradient(90deg, rgba(41,217,105,1) 19%, rgba(119,231,240,1) 100%);
    color: aliceblue;
    cursor: pointer;
    border-radius: 16px;
}

.title-tutorial {
    font-family: 'poppins';
    font-weight: 500;

}

.box-play {
    font-size: 11px;
    margin-right: 10%;
    color: rgb(182, 165, 233);
    border: 1px solid rgba(123,98,200,1);
    padding: 1px 5px 1px 5px;
    border-radius: 50%;
}

.box-pause {
    font-size: 11px;
    margin-right: 10%;
    color: rgb(251, 98, 98);
    border: 2px solid rgb(251, 98, 98);
    padding: 1px 5px 1px 5px;
    border-radius: 50%;
}

@media only screen and (max-width: 800px ) {
    .container-tutorial {
        display: none;
    }
}