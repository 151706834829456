.card-home {
    width: 80%;
    height: 13vh;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
    color: white;
}

/* .card:hover {
    background-color: 
} */

.content-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-style{
    margin-left: 3%;
}