@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');
.container-register {
    width: 100%;
}

.content-register {
    width: 90%;
    margin: auto;
    margin-top: 4%;
    font-family: 'poppins';
}

.container-button-show {
    width: 100%;
    display: flex;
    justify-content: end;
}

.container-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 95%;
}

.input-style-register {
    width: 100%;
    padding: 5px;
    padding-left: 3%;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 2%;
    /* box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1); */
    border: 1px solid #2D1674;
}

.input-style-image {
    width: 100%;
    padding: 5px;
    padding-left: 3%;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 2%;
    cursor: pointer;
    /* box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1); */
    border: 1px solid #2D1674;
}


.custom-file-input {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  
  /* .custom-file-input input {
    position: absolute;
    font-size: 100px;
    right: 0;
    top: 0;
    opacity: 0;

  } */
  
  .custom-file-input {
    width: 93%;
    display: inline-block;
    background: transparent;
    border: #2D1674 dashed 1px;
    color: #2D1674;
    padding: 8px 20px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
  }

  .label-file-input {
    cursor: pointer;
  }
  
  .file-name {
    display: block;
    margin-top: 3px;
    text-align: center;
    font-size: 13px;
    color: black;
  }

  .icon-file-upload {
    margin-left: 5px;
  }
  
  .custom-file-input input:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }

input[type="file"] {
    display: none;
}

.info-hours-and-minutes {
    font-size: 9.8px;
    margin-bottom: 5px;
    font-weight: 700;
    color: rgb(255, 93, 93);
}

.container-update-items {
    display: flex;
    align-items: center;
    /* margin-right: -5%; */
}

.input-style-edit-value {
    width: 40%;
    padding: 5px;
    padding-left: 3%;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    /* box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1); */
    border: 1px solid #2D1674;
    margin-right: 5%;
}

.container-icons-edit {
    width: 20%;
    margin: 0px;
}


.container-button-register{
    margin-top: 45%;
}

.button-style-register {
    width: 100%;
    padding: 7px 8px;
    background: #2D1674;
    border: none;
    border-radius: 20px;
    font-size: 13px;
    color: white;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    cursor: pointer;
}

.button-show-style-register {
    width: 20%;
    padding: 7px 8px;
    background: #2D1674;
    border: none;
    border-radius: 20px;
    font-size: 13px;
    color: white;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    cursor: pointer;
}

.button-style-register:hover {
    background: rgba(45, 22, 116, 0.9)
}

.container-search {
    width: 35%;
    display: flex;
    justify-content: space-around;
}

.container-button-search {
    margin-left: 1%;
}

.table-container {
    width: 100%;
    margin-top: 5%;
}

.container-icons-table-product {
    display: flex;
    justify-content: space-around;
    width: 50%;
    cursor: pointer;
}

.container-duration {
    /* text-align: center; */
    width: 8%;
}

.container-loading {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 15%;
}

.content-input-register {
    width: 80%;
}

.content-input-register-value {
    width: 100%;
    margin-top: 0%;
}

.container-product-list {
}

@media only screen and (max-width: 800px ) {
    .content-register {
        width: 100%;
        margin: auto;
        margin-top: 25%;
        margin-left: -10%;
    }
    .titles {
        font-size: 23px;
    }

    .container-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        /* margin-left: -40%; */
        margin-bottom: 3%;
    }

    .container-button-register{
        width: 100%;
        margin-top: 5%;
    }

    .container-search {
        width: 100%;
        display: flex;
        justify-content: space-around;
    }

    .content-input-register {
        width: 100%;
    }

    .content-input-register-value {
        width: 100%;
        margin-top: 5%;
    }

    .table-container {
        width: 100%;
        margin-top: 5%;
        font-size: 15px;
    }

    .container-icons-table-product {
        display: flex;
        justify-content: space-around;
        width: 100%;
        cursor: pointer;
    }

    .button-style-register {
        width: 100%;
        padding: 7px 8px;
        background: #2D1674;
        border: none;
        border-radius: 20px;
        font-size: 13px;
        color: white;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 1px;
        cursor: pointer;
        margin-bottom: 5%;
    }

    .container-duration {
        width: 100%;
    }

    .container-update-items {
        display: flex;
        align-items: center;
        width: 100%;
        /* margin-right: -5%; */
    }

    .input-style-edit-value {
        width: 80%;
        padding: 5px;
        padding-left: 3%;
        border-radius: 10px;
        font-family: 'Poppins', sans-serif;
        /* box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1); */
        border: 1px solid #2D1674;
        margin-right: 5%;
    }

    .container-icons-edit {
        width: 25%;
        margin: 0px;
    }

    .button-show-style-register {
        width: 70%;
        padding: 7px 8px;
        background: #2D1674;
        border: none;
        border-radius: 20px;
        font-size: 13px;
        color: white;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 1px;
        cursor: pointer;
        margin-bottom: 15%;
    }

    .container-button-show {
        width: 100%;
        display: flex;
        justify-content: start;
    }
}
