.table-container-sales-analysis {
    width: 65%;
    margin-top: 5%;
    text-align: center;
}

.container-inputs-analisys {
    width: 15%;
    margin-bottom: 2%;
    margin-right: 2%;
}

@media only screen and (max-width: 800px ) {
    .table-container-sales-analysis {
        width: 100%;
        margin-top: 5%;
        text-align: center;
    }

    .container-inputs-analisys {
        width: 80%;
        margin-bottom: 2%;
        margin-right: 2%;
    }
}