@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.container-personalize {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    font-family: 'poppins';
    width: 100%;
}

.container-title-personalize {
    width: 40%;
    margin-bottom: 5%;
}

.card-personalize {
    width: 40%;
    background: white;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 10px 15px;
    margin-bottom: 0.5%;
}

.button-style-personalize {
    width: 100%;
    padding: 7px 8px;
    background: #2D1674;
    border: none;
    border-radius: 20px;
    font-size: 13px;
    color: white;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    cursor: pointer;
}


@media only screen and (max-width: 800px ) {

    .card-personalize {
        width: 95%;
        background: white;
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        padding: 10px 15px;
        margin-bottom: 2.5%;
    }
    .container-personalize {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 5%;
        font-family: 'poppins';
        width: 100%;
    }

    .container-title-personalize {
        width: 90%;
        margin-bottom: 5%;
    }
    
    .button-style-personalize {
        width: 100%;
        padding: 7px 8px;
        background: #2D1674;
        border: none;
        border-radius: 20px;
        font-size: 13px;
        color: white;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 1px;
        cursor: pointer;
    }
    
   
}



