.container-cart {
    width: 100%;
}

.container-total-cart {
    display: flex;
    width: 35%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2%;
    cursor: pointer;
}

.container-badge {
    width: 20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 4px;
    /* border: 1px solid rgba(249,141,1,1); */
    border-radius: 20px;
}

.content-cart {
    width: 80%;
    margin: auto;
    margin-top: 4%;
}

.container-search-cart {
    width: 35%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 5%;
}

.container-list-product {
    width: 70%;
}

.container-product-cart {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 30% 20%;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin-top: 10px;
    padding: 5px 0px;
}

.content-product {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.content-cart-finish {
    display: grid;
    grid-template-columns:73% 23% 1%;
    /* justify-content: space-between; */
    margin-bottom: 10px;
    width: 100%;
}

.container-descount {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 3px 15px;
}

.input-style-descount {
    width: 40%;
    padding: 0px;
    padding-left: 3%;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    border: 1px solid #2D1674;
    margin-right: 5%;
}

.select-method {
    width: 40%;
    padding: 0px;
    padding-left: 3%;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    border: 1px solid #2D1674;
    margin-right: 5%;
    font-size: 12.5px;
    text-align: start;
}

.content-cart-total {
    margin: 35px 15px;
    display: flex;
    justify-content: space-between;
}

.style-total-title {
    font-weight: 600;
}

.style-value-total {
    color: #198754;
    font-weight: 600;
}

.container-button-cart {
    margin: 5px 10px;
}
.button-cart-cancel {
    width: 100%;
    padding: 6px 8px;
    border: solid 1px rgb(255, 70, 67);
    border-radius: 20px;
    font-size: 13px;
    color: rgb(255, 70, 67);
    background-color: white;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    cursor: pointer;
}

.button-cart-cancel:hover {
    border: none;
    background-color: rgb(255, 70, 67);
    color: white;
    transition: all 0.3s !important;
    padding: 6px 8px;
}

.container-button-finish {
    width: 40%;
    display: flex;
    justify-content: space-around;
}

.container-inputs-fee{
    display: flex;
    justify-content: space-between;
    width: 65%;
    margin-bottom: 5%;
}

.container-list-fees {
    display: grid;
    grid-template-columns: 65% 5%;
    align-items: center;
}

.value-fee {
    margin-left: 1%;
    margin-right: 3%;
}

.style-border-fee {
    margin: 0.6rem 0;
    width: 110%;
}

.button-style-fee {
    width: 100%;
    padding: 7px 8px;
    background: #2D1674;
    border: none;
    border-radius: 7px;
    font-size: 13px;
    color: white;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    cursor: pointer;
}

.button-style-cart {
    width: 100%;
    padding: 7px 8px;
    background: #2D1674;
    border: none;
    border-radius: 20px;
    font-size: 13px;
    color: white;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    cursor: pointer;
}

.button-style-cart:hover {
    background: rgba(45, 22, 116, 0.9)
}

@media only screen and (max-width: 800px ) {
    .content-cart {
        width: 100%;
        margin: auto;
        margin-top: 25%;
        margin-left: -10%;
        
    }

    .container-total-cart {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2%;
        cursor: pointer;
    }

    .container-search-cart {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-bottom: 15%;
    }

    .container-list-product {
        width: 100%;
    }

    .content-product {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 13px;
        padding-left: 10px;
    }
    
}