.container-header {
    width: 100%;
    height: 80px;
    padding: 10px;
    background-color: var(--main-color);
    display: flex;
    align-items: center;
    font-size: 22px;
    letter-spacing: 2px;
    color: var(--title-color);
}